import {getConst} from '../@core/constants';
import {DateTime} from 'luxon';
import {toDateTime} from '../utils/date.utils';
import {GetterCache} from '../utils/accessor.utils';

export type HumanNameStyle = 'firstNameFirst' | 'lastNameFirst';

export class Human {
    first_name: string;
    middle_name: string;
    last_name: string;
    title_name: string;
    name_suffix: string;
    date_ob: Date;
    gender: string;

    constructor(human?: any) {
        if (human) {
            this.first_name = human.first_name || null;
            this.middle_name = human.middle_name || null;
            this.last_name = human.last_name || null;
            this.title_name = human.title_name || null;
            this.name_suffix = human.name_suffix || null;
            this.date_ob = human.date_ob ? new Date(human.date_ob) : null;
            this.gender = human.gender || null;
        }
    }

    @GetterCache()
    get name(): string {
        return Human.getName(this);
    }

    set name(x) {
    }

    @GetterCache()
    get age(): number {
        return this.date_ob ? Math.trunc(DateTime.now().diff(toDateTime(this.date_ob), 'years').years) : undefined;
    }

    get genderText(): string {
        return Human.getGenderText(this);
    }

    get genderShort(): string {
        return Human.getGenderShort(this);
    }

    get genderSubject(): string {
        return this.gender == 'GENDER_M' ? 'man' : this.gender == 'GENDER_F' ? 'woman' : 'person';
    }

    static getName(human, style: HumanNameStyle = 'lastNameFirst', nameFieldPrefix: string = ''): string {
        if (!human) return '';
        switch (style) {
            case 'firstNameFirst' :
                return [human[`${nameFieldPrefix}title_name`], human[`${nameFieldPrefix}first_name`], human[`${nameFieldPrefix}middle_name`], human[`${nameFieldPrefix}last_name`], human[`${nameFieldPrefix}name_suffix`]].filter(x => x).join(' ');
            case 'lastNameFirst' :
                const firstNames = [human[`${nameFieldPrefix}first_name`], human[`${nameFieldPrefix}middle_name`]].filter(x => x).join(' ');
                return [human[`${nameFieldPrefix}last_name`], firstNames, human[`${nameFieldPrefix}title_name`], human[`${nameFieldPrefix}name_suffix`]].filter(x => x).join(', ');
        }
    }

    static getGenderText(human): string {
        return human.gender ? getConst(human.gender) : undefined;
    }

    static getGenderShort(human): string {
        return human.gender?.includes('GENDER_') ? human.gender.slice(7) : undefined;
    }
}
